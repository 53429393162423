import React from 'react';





const Resume = () => {

    return (
        <div className="App">
            {/*<header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
        </header>*/}

            {/* <h1>Harun Dastekin</h1>*/}

            {/* <head>
    <title>Harun Dastekin</title>
    <nav className="topnav">
      <ul>
        <li className="topnavitem"><a href=" ">my
          interests</a></li>
        <li className="topnavitem"><a href=" ">my
          personal projects</a></li>
        <li className="topnavitem"><a href=" ">my
          hobbies</a></li>

        <li className="topnavitem">

        </li>
      </ul>
    </nav>
  </head>*/}



            {/*<body>*/}


            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            <section className="hidden">
                <div>
                    <h1>Harun Dastekin</h1>
                    <h2><a href='https://github.com/hdi10'>@hdi10</a></h2>
                </div>
            </section >


            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            {/*////////////////////////////////////         PRAXISERFAHRUNG                     /////////////////////////////////////////*/}
            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            <section className="hidden">
                <h2>BERUFSERFAHRUNG</h2>


                <div>
                    <center><strong>12/2021 – 12/2023<hr></hr></strong>
                        <b>Werkstudent</b><br />
                        für die<br /> <b>Toll Collect GmbH</b><br />Berlin Mitte<br />
                        {/*in der <br/> Verfahrensentwicklung <br/> */}als<br /> Softwareentwickler Testautomatisierung
                    </center><br />

                    {/*in Berlin Mitte in der Verfahrensentwicklung als Softwareentwickler für die Testautomatisierung*/}
                    <ul>
                        <li>	Grafana Dashboard Visualisierung mit Fahrzeuggeräte Logs, Kamera Überwachung, Jira und Bugzilla Tickettracking</li>
                        <li>	Docker Containerization</li>
                        <li>	Testautomatisierung mit HP ALM und Microfocus UFT</li>
                    </ul>
                </div>

            </section >

            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            <section className="hidden">


                <div>
                    <center><strong>05/2021 – 12/2021</strong><hr></hr>
                        <b>Werkstudent</b><br />
                        für die<br /><b>Entraserv GmbH</b><br />Berlin Oberschöneweide<br />
                        als<br /> IT-Berater und Softwareentwickler
                    </center><br />
                    <ul>
                        <li>	IT-Berater für Apple IPad Kassensysteme</li>
                        <li>	Android App Entwicklung für die Fahrzeugüberführung</li>
                        <li>	Bildbearbeitung mit GIMP</li>
                        <li>	Videobearbeitung mit Biteable</li>
                        <li>	Unterstützung im Vertrieb</li>
                    </ul>
                </div>

            </section >

            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}

            <section className="hidden">


                <div>
                    <center><strong>04/2019 - 04/2021<br /></strong><hr></hr>
                        <b>Werkstudent</b><br />
                        für die<br /><b>Stromnetz Berlin GmbH</b><br />Berlin Treptow<br />
                        im Bereich <br /> Trassenmanagement und Leitungsauskunft
                    </center><br />
                    <ul>
                        <li>Beantwortung von Leitungsanfragen</li>
                        <li>Unterstützung SharePoint Präsenz</li>
                    </ul>
                </div>

            </section>

            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}

            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            {/*//////////                         BILDUNGSWEG                            ////////////////////////////////////////////////*/}
            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            <section className="hidden">
                <h1>BILDUNGSWEG</h1>
                <div><screenLeft>
                    <strong> Seit 09.2024 ISTQB Certified Tester Foundation Level(CTFL)<br /></strong>
                </screenLeft>
                </div>
                <div><center><hr></hr>
                    <strong>04/2019 - 11/2024<br /></strong>
                    Bachelor-Studium der<br /> <b>Angewandte Informatik (B.Sc.)</b><br />
                    an der<br /> <b>Hochschule für Technik und Wirtschaft (HTW)</b> <br />Berlin
                </center>
                </div>

            </section >

            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            <section className="hidden">
                <div><center>
                    <strong>10/2014 - 03/2019<br /></strong><hr></hr>
                    Bachelor-Studium der<br /> <b>Regenerative Energien (B.Sc.) (ohne Abschluss)</b><br />
                    an der<br /> <b>Hochschule für Technik und Wirtschaft (HTW)</b> <br />Berlin
                </center><br />

                    {/* Bachelorthesis: „Optimierung solarer Wärmespeicher
                    Anhand unterschiedlicher Regelungsstrategien“<br /><br />

                    <ul>
                        <li>Mechanische Energiewandlung</li>
                        <li>Solare Energiewandlung</li>
                        <li>Thermo- / chemische Energiewandlung</li>
                        <li>Wind- und Wasserkraftsysteme</li>
                        <li>Solare Energiesysteme</li>
                        <li>Biomasse und thermische Systeme</li>
                        <li>Projekt Regenerative Energiesysteme</li>
                    </ul> */}


                </div>


            </section >


            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            <section className="hidden">


                <div>
                    <center><strong>08/2011 - 07/2014<br /></strong><hr></hr>
                        Berufsfachschule<br /> <b>staatlich geprüfter energietechnischer Assistent)</b><br />
                        am<br /> <b>Oberstufenzentrum Technische Informatik, Industrieelektronik, Energiemanagement (OSZ TIEM) </b> <br />Berlin<br />
                        (Fachabiturabschlussnote: 2,4)
                    </center><br />


                    {/* Prüfungsfächer:
                    <ul>
                        <li>	Erneuerbare Energien</li>
                        <li>Energiemanagement</li>
                    </ul>

                    Ausbildungsinhalte (Praktika):
                    <ul>
                        <li>Installation / Montage</li>
                        <li>Elektrotechnik</li>
                        <li>Regenerative Energietechnik</li>
                        <li>Datenverarbeitung / </li>
                    </ul> */}
                </div>

            </section >


            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            {/*////////////////////////           AUßERUNIVERSITÄRES ENGAGEMENT</h1>                        /////////////////////////////*/}
            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            <section className="hidden">
                <h1>EHRENAMT</h1>

                <div>
                    <center><strong>12/2018 - 02/2019<br /></strong>
                        <strong>12/2016 - 01/2017<br /></strong>
                        <strong>11/2015 - 03/2016<br /></strong><hr></hr>
                        <b>Ehrenamtliche Aushilfe</b><br />
                        für eine Gemeinnützige Einrichtung<br />
                        der <b>Gebewo</b><br />in Berlin
                        <br />während der Wintermonate<br />

                    </center><br />
                    <ul>
                        <li>Organisation und Durchführung der Arbeit am Abend</li>
                        <li>Führen von thematischen Einzel- und Gruppengesprächen</li>
                        <li>Informationen zu möglichen Beratungs- und Hilfsangeboten</li>
                        <li>Lösen von Konfliktsituationen</li>
                        <li>Statistik und Dokumentation</li>
                    </ul>
                </div>

            </section>
            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            {/*////////////////////////////////           KENNTNISSE & FÄHIGKEITEN              /////////////////////////////////////////*/}
            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            <section className="hidden">
                <h1>KENNTNISSE & FÄHIGKEITEN</h1>
                <h2>Sprachen</h2>
                <ul>
                    <li>Deutsch (fließend)</li>
                    <li>Türkisch (fließend)</li>
                    <li>Englisch (fortgeschritten)</li>
                </ul>

            </section>
            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            <section className="hidden">

                <h2>IT-Kenntnisse</h2>

                <div>
                    <div>
                        <h3>Microsoft Office</h3>
                        <ul>
                            <li>MS Word (sehr gut)</li>
                            <li>MS Excel (sehr gut)</li>
                            <li>MS PowerPoint (sehr gut)</li>
                            <li>One Note (sehr gut)</li>
                        </ul>
                    </div>

                    <div>
                        <h3>Apple Iwork</h3>
                        <ul>
                            <li>Pages (gut)</li>
                            <li>Numbers (gut)</li>
                            <li>Keynote (gut)</li>
                        </ul>
                    </div>
                </div>

                <h2>Programme und Sprachen</h2>
                <h3>IT-Software oder Toolchain</h3>
                <div>
                    Node RED, Grafana, Influx, TelegramBot<hr></hr>
                    Android Studio, IntelliJ, Eclipse, VSCode<hr></hr> Latex, Git,
                    Arduino IDE, GIMP, Spring,<hr></hr>
                    Docker, Shell-Scripting,
                    Batch-Scripting, JUnit,<hr></hr> Android Espresso Testing, Spring,
                    REST, Zephyr,<hr></hr> Powershell, VBA, Jira, Git, Maven,<hr></hr>
                    Gradle, Elasticsearch, Heroku, GitLab CD/CI
                </div>
                <h3>Programmiersprachen</h3>
                <div>
                    Java, C, C++,<hr></hr>
                    Javascript, Typescript
                    Android,<hr></hr>
                    MicroPython,
                    PostgreSQL, Influx
                </div>

            </section>

            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            {/*////////////////////////////             QUALIFIKATIONEN                  ////////////////////////////////////////////////*/}
            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            <section className="hidden">
                <h1>QUALIFIKATIONEN</h1>
                <div>
                    Zertifizierter Mediator (Konfliktlotse)<hr></hr>
                    Hochschul-Fremdsprachenzertifikat in Englisch (UNIcert II -Englisch)<hr></hr>
                    ISTQB Certified Tester Foundation Level(CTFL) - Zertifikatsnummer: 24-CTFL 4-251342-01 </div>

            </section>
            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            {/*////////////////////////////             My Personal Projects                  ///////////////////////////////////////////*/}
            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            <section className="hidden">
                <div>
                    <h2>Zu meinen Interessen gehören</h2>
                    <p>
                        {/*<b Stromnetz, VMWare, Docker, Keycloak, NodeJS, Oracle MySQL, PostgreSQL, Amazon Web Services(AWS),Kubernetes, Angular, Spring Boot, TypeScript, Cisco */}
                        MicroPython , ESP32, Software, Hardware, Mikroelektronik<hr></hr> Java, Android, Grafana, Testing,<hr></hr>
                        Git und GitLab CD/CI , PostgreSQL als DBMS, Spring Boot als Microservice Ecosystem
                    </p>
                </div>




            </section>
            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            {/*////////////////////////////             Hobbies                               ///////////////////////////////////////////*/}
            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            <section className="hidden">
                <h1>Hobbies</h1>
                <p>Meine Hobbies sind Bodenturnen, Kampfsport und Lesen.</p>
            </section>
            {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
            {/*</body>*/}


        </div>

    )
};

export default Resume;
